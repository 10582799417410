export const enum STAND {
  BAMBOO = 'bamboo',
  WALNUT = 'walnut',
  LIGHT = 'light',

  NO_STAND = 'no_stand',
  ONE_STAND = 'one_stand',
  TWO_STAND = 'two_stand',
  THREE_STAND = 'three_stand',
  FOUR_STAND = 'four_stand',
  FIVE_STAND = 'five_stand',
  SIX_STAND = 'six_stand',
  SEVEN_STAND = 'seven_stand',
  EIGHT_STAND = 'eight_stand',
  NINE_STAND = 'nine_stand',
  FOURTEEN_STAND = 'fourteen_stand',
}

export const STANDS_IMAGES_ALIAS_MAP = {
  [STAND.BAMBOO]: 'stand:bamboo',
  [STAND.WALNUT]: 'stand:walnut',
  [STAND.LIGHT]: 'stand:light',
  [STAND.NO_STAND]: '',

  [STAND.ONE_STAND]: 'stand:one_stand',
  [STAND.TWO_STAND]: 'stand:two_stands',
  [STAND.THREE_STAND]: 'stand:three_stands',
  [STAND.FOUR_STAND]: 'stand:four_stands',
  [STAND.FIVE_STAND]: 'stand:five_stands',
  [STAND.SIX_STAND]: 'stand:six_stands',
  [STAND.EIGHT_STAND]: 'stand:eight_stands',
  [STAND.NINE_STAND]: 'stand:nine_stands',
  [STAND.FOURTEEN_STAND]: 'stand:fourteen_stands',
}

export const standColorVariationToStand = {
  Bamboo: STAND.BAMBOO,
  Walnut: STAND.WALNUT,
  Light: STAND.LIGHT,

  'No Stand': STAND.NO_STAND,
  '1 Stand': STAND.ONE_STAND,
  '2 Stands': STAND.TWO_STAND,
  '3 Stands': STAND.THREE_STAND,
  '4 Stands': STAND.FOUR_STAND,
  '5 Stands': STAND.FIVE_STAND,
  '6 Stands': STAND.SIX_STAND,
  '7 Stands': STAND.SEVEN_STAND,
  '8 Stands': STAND.EIGHT_STAND,
  '9 Stands': STAND.NINE_STAND,
  '14 Stands': STAND.FOURTEEN_STAND,
}

export const STANDS_TO_PICKER_IMAGE = {
  [STAND.BAMBOO]: '/stands/bamboo.svg',
  [STAND.WALNUT]: '/stands/walnut.svg',
  [STAND.NO_STAND]: '/stands/no_stand.svg',
}

export const ALL_STANDS_ASSETS_ALIAS = 'stand:all'

export const isVariantSKUAStand = (sku: string) => sku.startsWith('MXT-WS')

export const NO_STAND_DEFAULT_VARIANT = {
  title: 'No Stand',
  id: 0,
  handle: 'no_stand',
}
